import Vue from 'vue'
import VueRouter from 'vue-router'
// import ChooseDeckView from '../views/ChooseDeckView.vue'
import GenerateDeckView from '../views/GenerateDeckView.vue'

import store from '../store'

Vue.use(VueRouter)

const checkGameReady = (to, from, next) => {
    if (to.name !== 'GenerateDeck' && !store.getters.gameReady) next({ name: 'GenerateDeck' })
    else next()
}

const routes = [{
        path: '/',
        name: 'GenerateDeck',
        component: GenerateDeckView
    },
    {
        path: '/play',
        name: 'play',
        beforeEnter: checkGameReady,
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "play" */ '../views/PlayView.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router