import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({

    state: {
        gameReady: false,
        isFlipped: false,
        gameOver: false,
        driveAuth: {},
        flipInterval: 600,
        currentCard: {},
        currentColor: "",
        cardDecks: [{
                "name": "deck2",
                "cards": [{
                        "type": "last one to",
                        "text": "last one to sing drinks"
                    },
                    {
                        "type": "song names",
                        "text": "name a song you like"
                    }
                ]
            },
            {
                "name": "deck1",
                "cards": [{
                        "type": "would you rather",
                        "text": "a man walks into a bar"
                    },
                    {
                        "type": "categories",
                        "text": "name somethings"
                    }
                ]
            }
        ],
        cardDeck: [
            { TYPE: "Truth", TEXT: "What is the weirdest thing you have ever done while drunk?" },
            { TYPE: "Dare", TEXT: "Take a shot of hot sauce" },
            { TYPE: "Truth", TEXT: "Tell a story about a time when you embarrassed yourself in front of someone you were attracted to" },
            { TYPE: "Dare", TEXT: "Sing a karaoke song in front of the group" },
            { TYPE: "Truth", TEXT: "What is the worst hangover you have ever had?" },
            { TYPE: "Dare", TEXT: "Do a shot while standing on one foot" },
            { TYPE: "Truth", TEXT: "Tell a story about a time when you were rejected by someone you were attracted to" },
            { TYPE: "Dare", TEXT: "Do a shot while blindfolded" },
            { TYPE: "Truth", TEXT: "What is your wildest party story?" },
            { TYPE: "Dare", TEXT: "Take a shot using only your mouth (no hands allowed)" }
        ],
        cardStyles: {
            "Most likely to": {
                card: {
                    backgroundColor: "#f44336"
                },
            },
            "Would you rather": {},
            "General": {},
            "Never have I ever": {},
            "21": {},
            "Questions": {},
            "Game Over": {
                card: {
                    backgroundColor: "#B71C1C",
                },
            },
            "Truth": {},
            "Dare": {}
        },
        cardColors: [
            "#C2185B",
            "#7B1FA2",
            "#7e49ff",
            "#485de6",
            "#1976D2",
            "#0288D1",
            "#0097A7",
            "#00796B",
            "#388E3C",
            "#689F38",
            "#AFB42B",
            "#FFA000",
            "#F57C00",
            "#E64A19",
        ],
    },
    getters: {
        isFlipped: state => state.isFlipped,
        gameOver: state => state.gameOver,
        driveAuth: state => state.driveAuth,
        flipInterval: state => state.flipInterval,
        currentCard: state => state.currentCard,
        currentColor: state => state.currentColor,
        cardDecks: state => state.cardDecks,
        cardDeck: state => state.cardDeck,
        cardStyles: state => state.cardStyles,
        cardColors: state => state.cardColors,
        gameReady: state => state.gameReady,
    },
    mutations: {
        setIsFlipped: (state, isFlipped) => {
            state.isFlipped = isFlipped
        },
        setGameOver: (state, gameOver) => {
            state.gameOver = gameOver
        },
        setDriveAuth: (state, driveAuth) => {
            state.driveAuth = driveAuth
        },
        setFlipInterval: (state, flipInterval) => {
            state.flipInterval = flipInterval
        },
        setCurrentCard: (state, currentCard) => {
            state.currentCard = currentCard
        },
        setCurrentColor: (state, currentColor) => {
            state.currentColor = currentColor
        },
        setCardDecks: (state, cardDecks) => {
            state.cardDecks = cardDecks
        },
        setCardDeck: (state, cardDeck) => {
            state.cardDeck = cardDeck
        },
        setCardStyles: (state, cardStyles) => {
            state.cardStyles = cardStyles
        },
        setCardColors: (state, cardColors) => {
            state.cardColors = cardColors
        },
        setGameReady: (state, gameReady) => {
            state.gameReady = gameReady
        }
    },
    actions: {
        setGameReady: ({ commit }) => {
            commit('setGameReady', true)
        },
        flipCard: ({ commit, getters }) => {
            let isFlipped = getters.isFlipped
            commit('setIsFlipped', !isFlipped)
        },
        endGame: ({ commit }, gameOver) => {
            commit('setGameOver', gameOver)
        },
        setDriveAuth: ({ commit }, driveAuth) => {
            commit('setDriveAuth', driveAuth)
        },
        setFlipInterval: ({ commit }, flipInterval) => {
            commit('setFlipInterval', flipInterval)
        },
        setCurrentCard: ({ commit }, currentCard) => {
            commit('setCurrentCard', currentCard)
        },
        setCurrentColor: ({ commit }, currentColor) => {
            commit('setCurrentColor', currentColor)
        },
        setCardDecks: ({ commit }, cardDecks) => {
            commit('setCardDecks', cardDecks)
        },
        setCardDeck: ({ commit }, cardDeck) => {
            commit('setCardDeck', cardDeck)
        },
        setCardStyles: ({ commit }, cardStyles) => {
            commit('setCardStyles', cardStyles)
        },
        setCardColors: ({ commit }, cardColors) => {
            commit('setCardColors', cardColors)
        },
        resetGame: ({ commit, getters, dispatch }) => {
            //Destructure the state to make it easier to reference
            let { cardDeck } = getters

            // Reset the shown property of all cards in the card deck to false
            cardDeck.forEach(card => card.shown = false)

            // Reset the game over property to false
            commit('setGameOver', false)

            // Display a new random card
            dispatch('displayRandomCard')
        },
        markCardShown: ({ commit, getters, dispatch }, card) => {
            //Destructure the state to make it easier to reference
            let { cardDeck, currentCard } = getters

            // Find the current card in the card deck and mark it as shown
            cardDeck.find(card => card.TEXT === currentCard.TEXT).shown = true
        },
        displayRandomCard: ({ commit, getters, dispatch }) => {

            //Destructure the state to make it easier to reference
            let { isFlipped, gameOver, cardDeck, flipInterval, currentCard } = getters

            //Dont accept any more clicks while the card is flipping or the game is over
            if (isFlipped || gameOver)
                return

            // Filter the card deck to only include cards that have not been shown
            const remainingCards = cardDeck.filter(card => !card.shown)

            // Get a random index from the remaining cards
            const randomIndex = Math.floor(Math.random() * remainingCards.length)

            dispatch('flipCard')

            setTimeout(() => { dispatch('flipCard') }, flipInterval);

            // Set the current card to the card at the random index and mark it as shown
            //Wait half a second before updating the card
            // If there are no remaining cards, set the current card to a "game over" message
            if (remainingCards.length === 0) {
                setTimeout(() => {

                    dispatch('setCurrentCard', { TYPE: "Game Over", TEXT: "The game is over. No more cards to show." })
                    commit('setGameOver', true)
                }, flipInterval / 2);
                return
            }
            setTimeout(() => {
                dispatch('setCurrentCard', remainingCards[randomIndex]);
                dispatch('markCardShown', remainingCards[randomIndex]);
            }, flipInterval / 2);
        }
    }
})