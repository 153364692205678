<template>
  <div id="app">
    <div class="game-container">
      <div v-if="$route.name !== 'GenerateDeck'" class="phone-spacer"></div>
      <router-view />
      <br>
      <div class="phone-spacer"></div>
      <GameControls v-if="$route.name === 'play'"/>
    </div>
  </div>
</template>
<script>
import GameControls from '@/components/GameControls.vue'
export default {
  name: 'App',
  components: {
    GameControls,
  },
  created() {
    document.title = 'AyaLawd!'
  }
}
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;1,300;1,400;1,500;1,600&display=swap');

:root {
    --primary-color: #333 !important;
    --secondary-color: #ccc !important;
    --tertiary-color: black !important;
}

body {
  background-color: #212121 !important;
  color: white;
  font-family: sans-serif;
  font-family: "Montserrat", "Helvetica Neue", sans-serif;
}

// #app {
//   background-color: #212121 !important;
//   color: white;
//   font-family: sans-serif;
//   font-family: "Montserrat", "Helvetica Neue", sans-serif;
// }

.game-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.btn {
    background-color: var(--primary-color);
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 2em;
    font-weight: 500;
    cursor: pointer;
    user-select: none;
    margin: 10px;
    transition: all 0.15s ease-in-out;
}

@media(hover: hover) {
    .btn:hover {
        background-color: var(--secondary-color);
        color: var(--primary-color);
    }
}

@media only screen and (max-width: 600px) {
    .phone-spacer {
        height: 75px;
    }
}
</style>