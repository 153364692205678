<template>
<div class="generate-deck">
  <template v-if="loading">
    <Spinner :loading="loading" style="margin-top: 100px;"/>
    <h1>Fetching Cards...</h1>
  </template>
    <template v-if="!loading && !gameReady">
      <h1>Generate a Deck</h1>
      <textarea v-model="deckPrompt" id="generate-deck-input" rows="5"></textarea>
      <button @click="generateDeck(2)" class="btn btn-primary">Generate Deck!</button>
    </template>
</div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from 'vuex'

import Spinner from '@/components/Spinner.vue'

export default {
    name: 'GenerateDeck',
    components: {
        Spinner
    },
    data() {
        return {
            deckPrompt: '',
            loading: false,
        }
    },
    created() {
        const p1 = require('@/assets/prompt1.txt')
        this.prompt1 = p1

        const p2 = require('@/assets/prompt2.txt')
        this.prompt2 = p2
    },
    computed: {
        ...mapGetters(['gameReady']),
    },
    methods: {
        ...mapActions(['setCardDeck', 'setGameReady']),
        splitOnFirst(string, separator) {
            const index = string.indexOf(separator);
            if (index === -1) {
                return [string];
            }

            return [
                string.substring(0, index),
                string.substring(index + separator.length),
            ];
        },
        async generateDeck(n) {
            // Set loading state
            this.loading = true
            const promises = Array.from({
                length: n
            }, () => this.generateTenCards());
            const results = await Promise.all(promises);
            // console.log('Results from Promise:',results); // outputs an array of the 'n' outputs from the createDeck() function
            let cards = []

            results.forEach((subDeck) => {
                // parse the CSV text into an array of objects
                const rows = subDeck.split('\n').map(row => this.splitOnFirst(row, ','));
                //rows.shift(); // remove the header row
                const newCards = rows.map(row => ({
                    TYPE: row[0].replace('\r', '').replace('\"', '').trim(),
                    TEXT: row[1].replace('\"', '').trim()
                }));

                cards.push(...newCards)
            });

            // console.log('Final Deck:', cards)
            // console.log('Final Deck Length:', cards.length)

            // // Assign cards to deck
            this.setCardDeck(cards)

            // // Ready the game then route to play
            this.setGameReady()
            this.loading = false

            this.$router.push({
                name: 'play'
            })
        },
        async generateTenCards() {
            // console.log(this.prompt1.replace('[PHRASE]', this.deckPrompt))
            // return
            let response = await this.$openai.createCompletion({
                model: "text-davinci-003",
                prompt: this.prompt1.replace('[PHRASE]', this.deckPrompt),
                temperature: 1,
                max_tokens: 750,
                top_p: 1,
                frequency_penalty: 0.7,
                presence_penalty: 0.25
            });
            let p1 = response.data.choices[0].text.trim();
            // console.log('P1:', p1)

            response = await this.$openai.createCompletion({
                model: "text-davinci-003",
                prompt: this.prompt2.replace('[DECK-HERE]', p1).replace('[PHRASE]', this.deckPrompt),
                temperature: 1,
                max_tokens: 1000,
                top_p: 1,
                frequency_penalty: 0.25,
                presence_penalty: 0.35
            });
            const csvText = response.data.choices[0].text.trim();
            // console.log('prompt2:', this.prompt2.replace('[DECK-HERE]', p1).replace('[PHRASE]', this.deckPrompt))
            // console.log('P2:', csvText)

            return csvText
        }
    },
}
</script>

<style lang="scss" scoped>
.generate-deck {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

#generate-deck-input {
    width: 100%;
    font-size: 2em;
    font-weight: 500;
    padding: 10px;
    border-radius: 10px;
    border: none;
    resize: none;
    background-color: var(--secondary-color);
    color: var(--primary-color);
    margin-bottom: 20px;
}
</style>
