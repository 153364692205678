<template>
    <div class="spinner-container" v-if="loading">
      <div class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Spinner',
    props: {
      loading: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style scoped>
  .spinner-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .spinner {
    border: 6px solid #f3f3f3;
    border-radius: 50%;
    border-top: 6px solid #3498db;
    width: 50px;
    height: 50px;
    -webkit-animation: spin 1.5s linear infinite;
    animation: spin 1.5s linear infinite;
  }
  
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>
  