<template>
    <button @click="resetGame" class="btn btn-primary">Reset Game</button>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'GameControls',
  methods: {
    ...mapActions(['resetGame']),
  },
}
</script>

<style scoped lang="scss">

</style>
