import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { Configuration, OpenAIApi } from 'openai'

const configuration = new Configuration({
    apiKey: "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",
})

const openai = new OpenAIApi(configuration)

Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')

Vue.prototype.$openai = openai